import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["dropContainer", "imgContainer", "imgCover", "imgCoverPage", "imgForm"]

    connect() {
    }

    dropContainerTargetConnected(element) {
        this.dropContainerJ = $(element)
    }

    imgContainerTargetConnected(element) {
        this.imgContainerJ = $(element)
    }

    imgCoverTargetConnected(element) {
        this.imgCoverJ = $(element)
    }

    imgCoverPageTargetConnected(element) {
        // console.log("cover page")
        this.imgCoverPageJ = $(element)
    }

    imgFormTargetConnected(element) {
        this.imgFormJ = $(element)
    }

    containerShow(event) {
        this.imgContainerJ.show().addClass('d-flex')
        this.imgFormJ.hide()
        this.imgFile = this.imgCoverPageJ[0].files[0]
        this.reader = new FileReader()
        if (this.imgFile) {
            this.reader.readAsDataURL(this.imgFile)
            this.reader.onloadend = () => {
                this.imgCoverJ[0].src = this.reader.result
            }
        }
    }

    clickImgInput(event) {
        this.imgCoverPageJ.click()
    }

    removeImg(event) {
        this.imgContainerJ.hide().removeClass('d-flex')
        this.imgFormJ.show()
    }

}
