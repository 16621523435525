import {Controller} from 'stimulus';
import VimeoPlayer from "@vimeo/player";

window.player = VimeoPlayer;

// Global variables here (available through all controllers present in page)

export default class extends Controller {
    // Local variables using this.{variableName} (available through each individual controller

    static targets = ["preview", "videoForm", "show", "inputSecondsPreview", "inputEmbedId"]
    static values = {
        id: String,
        h: String,
        q: String,
        s: String,
        statUrl: String,
        streakUrl: String,
        admin: Boolean,
        rateable: Boolean
    }

    connect() {
        this.regex = /[a-zA-Z0-9]{9}\/[a-zA-Z0-9]{10}$/;
    }

    videoFormTargetConnected(element) {
        this.vidContainer = $(element)

    }

    inputEmbedIdTargetConnected(element) {

        if (this.idValue && this.hValue) {
            //TODO code refactoring
            this.options = {
                url: "https://player.vimeo.com/video/" + this.idValue + "?h=" + this.hValue,
                quality: this.qValue
            };

            this.vidPlayer = new player(this.videoFormTarget, this.options);

            // Library error!! (Does not correctly receive params)
            // this.vidPlayer.loadVideo(this.options).catch(()=>{
            //     console.log("Error loading video")
            // })

            this.vidPlayer.setCurrentTime(this.sValue)
            this.vidPlayer.on('seeked', (data) => {
                this.inputSecondsPreviewTarget.value = parseInt(data.seconds)
            })
        }
    }

    showTargetConnected(element) {

        this.vidContainer = $(this.showTarget)

        this.options = {
            url: "https://player.vimeo.com/video/" + this.idValue + "?h=" + this.hValue,
            quality: this.qValue,
            autoplay: true
        };

        this.vidPlayer = new player(this.showTarget, this.options);


        // If not admin
        if (!(this.hasAdminValue && this.adminValue)) {

            this.saved_seen = false
            this.saved_streak = false
            this.played_seconds = 0
            this.total_seconds = 0
            this.wait_time = 10
            this.vidPlayer.getDuration().then((duration) => {
                this.total_seconds = duration
            });

            this.myRating = $('.my-rating');
            this.rateModal = $('#rateModal');
            this.rateVideo = $('#rate_video');

            this.counted = false;
            this.vidPlayer.setCurrentTime(this.sValue)

            this.save_seconds = () => {
                this.vidPlayer.getCurrentTime().then((seconds) => {
                    ajax_stat({video_stat: {seconds_current: seconds}}, this.statUrlValue, 'PUT')
                });
            }

            let save_seen = () => {
                this.saved_seen = true
                ajax_stat({video_stat: {seen: "true"}}, this.statUrlValue, 'PUT')
            }

            let save_streak = () => {
                this.saved_streak = true
                ajax_stat({}, this.streakUrlValue, 'POST', (response) => {
                    if (response.streak === "ok") {
                        this.vidPlayer.pause()
                    }
                })
            }

            this.increase_save_seconds = () => {
                this.vidPlayer.getCurrentTime().then((seconds) => {
                    ajax_stat({
                        video_stat: {
                            seconds_current: seconds,
                            seconds_increase: this.wait_time
                        }
                    }, this.statUrlValue, 'PUT')
                });
                this.played_seconds += this.wait_time
                if (this.total_seconds !== 0) {
                    if (this.played_seconds >= this.total_seconds * 0.8) {
                        save_seen()
                        save_streak()
                    }
                }
            }

            let increment_play_count = () => {
                ajax_stat({video_stat: {started: true}}, this.statUrlValue, 'PUT')
            }

            this.vidPlayer.on('play', () => {
                if (!this.counted) {
                    increment_play_count()
                    this.counted = true
                }

                this.interval = setInterval(this.increase_save_seconds, this.wait_time * 1000)

            });

            this.vidPlayer.on('pause', () => {
                clearInterval(this.interval)
            });

            this.vidPlayer.on('seeked', () => {
                clearInterval(this.interval)
                this.save_seconds()
            });

            this.vidPlayer.on('ended', () => {
                clearInterval(this.interval)
                this.vidPlayer.setCurrentTime(0)
                if (this.rateableValue) {

                    if (this.myRating.length && this.rateModal.length) {
                        this.myRating.starRating({
                            starSize: 50,
                            disableAfterRate: false,
                            callback: (currentRating, $el) => {
                                this.rateVideo.val(currentRating)
                            }
                        });

                        this.myModal = new modal(this.rateModal[0], {
                            keyboard: false
                        })
                    }

                    this.myModal.show()
                }
            });
        }

    }

    previewTargetConnected(element) {
        this.vidContainer = $(this.previewTarget)
        this.vidContainer.hide()
        this.previewed = false
    }

    updateEmbed(event) {
        if (this.regex.test(event.target.value)) {

            this.embedId = event.target.value.split("/")
            this.options = {
                url: "https://player.vimeo.com/video/" + this.embedId[0] + "?h=" + this.embedId[1],
            };
            this.vidPlayer = new player(this.videoFormTarget, this.options)
            this.vidPlayer.setCurrentTime(this.sValue)
            this.vidPlayer.on('seeked', (data) => {
                this.inputSecondsPreviewTarget.value = parseInt(data.seconds)
            })
        } else {
            this.vidPlayer.destroy().catch(() => {

            })
        }
    }

    updateVideo(event) {
        this.sValue = event.target.value
        this.vidPlayer.setCurrentTime(this.sValue).catch(() => {
            //VideoPlayer not created or seconds beyond video duration
        })
    }


    prevStop(event) {

        if (this.previewed) {
            clearTimeout(this.timeOut);
            hidePlayer(this.vidContainer, this.vidPlayer, this.sValue)
        }

    }

    prevStart(event) {

        if (!this.previewed) {
            this.options = {
                url: "https://player.vimeo.com/video/" + this.idValue + "?h=" + this.hValue,
                controls: false,
                dnt: false,
                keyboard: false,
                muted: true,
                quality: this.qValue
            };
            this.vidPlayer = new player(this.previewTarget, this.options);

            showPlayer(this.vidContainer, this.vidPlayer, this.sValue)

            this.vidPlayer.on('timeupdate', () => {
                this.vidPlayer.getCurrentTime().then((seconds) => {
                    if (seconds >= this.sValue + 20) {
                        hidePlayer(this.vidContainer, this.vidPlayer, this.sValue)
                    }
                });
            })

            this.previewed = true

        } else {
            this.timeOut = setTimeout(() => {

                // Create player if not initialized
                showPlayer(this.vidContainer, this.vidPlayer, this.sValue)

            }, 500);
        }
    }
}

function ajax_stat(params, url, type, callback = null) {
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Content-Type': 'application/json'
        },
        url: url,
        type: type,
        data: JSON.stringify(params),
        success: (response) => {
            if (callback) {
                callback(response)
            }
            // console.log("Successful ajax request");
        },
        error: () => {
            // console.log("Error ajax request");
        }
    });
}

function showPlayer(container, player, seconds) {
    player.setCurrentTime(seconds);
    player.play().then(() => {
        container.show();
    }).catch(() => {
        //VideoPlayer not created or seconds beyond video duration
    })
}

function hidePlayer(container, player, seconds) {
    container.hide();
    player.setCurrentTime(seconds);
    player.pause();
}