// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as ActiveStorage from "@rails/activestorage"
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import VimeoPlayer from '@vimeo/player'
import {Tooltip, Toast, Popover, Modal, Dropdown} from 'bootstrap';
import Chart from 'chart.js/auto';
import "@nathanvda/cocoon";
import Swiper from 'swiper';
import {Navigation, Pagination, Grid} from 'swiper/modules';
import 'star-rating-svg/src/jquery.star-rating-svg'
import Countdown from 'countdown-js/index.js'
import '@/controllers'
import "trix"
import "@rails/actiontext"

Trix.config.blockAttributes.heading = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.subHeading = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
}

window.countDown = Countdown
window.toolTip = Tooltip
window.modal = Modal
window.toast = Toast
window.chart = Chart
window.player = VimeoPlayer
window.dropdown = Dropdown
window.swiper = Swiper.use([Navigation, Pagination, Grid])

Rails.start()
ActiveStorage.start()