import {Controller} from 'stimulus';

// Global variables here (available through all controllers present in page)

export default class extends Controller {
    // Local variables using this.{variableName} (available through each individual controller

    static targets = ["courses", "routes"]

    connect() {
    }

    showCourses() {
        this.coursesTarget.classList.remove("d-none")
        this.routesTarget.classList.add("d-none")
    }

    showRoutes() {
        this.coursesTarget.classList.add("d-none")
        this.routesTarget.classList.remove("d-none")
    }
}