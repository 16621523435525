import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["message"]
    connect() {
        if (this.hasMessageTarget) {
            let toastElList = [].slice.call(this.messageTargets)
            let toastList = toastElList.map(function (toastEl) {
                return new toast(toastEl, {delay: 2500})
            })
            toastList.map(function (toastEl) {
                toastEl.show()
            })
        }
    }
}