import {Controller} from 'stimulus';

export default class extends Controller {
    static values = {
        returnUrl: String
    }

    connect() {
        // This is a public sample test API key.
        // Don’t submit any personally identifiable information in requests made with this key.
        // Sign in to see your own test API key embedded in code samples.
        this.stripe = Stripe("pk_live_51JXQyCDpnnHA5c3dbhXNRi7kDtiCB2pogMWjlESPF5oajJ7TsYLD4mutahXFOHA2K5gVSFpqPgCULosEI8lpWPUp00RJ0O3TaC", {
            locale: 'es-419'
        });

        this.initialize_url = "/student/profile/checkout_setup_intent.json";
        this.return_url = this.returnUrlValue;
        this.elements = [];

        this.initialize();
        this.checkStatus();

        document
            .querySelector("#payment-form")
            .addEventListener("submit", (e) => {
                this.handleSubmit(e)
            });

    }

    // Fetches the payment intent status after payment submission
    async checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        const {setupIntent} = await this.stripe.retrieveSetupIntent(clientSecret);

        switch (setupIntent.status) {
            case "succeeded":
                this.showMessage("Payment method succeeded!");
                break;
            case "processing":
                this.showMessage("Your payment method is processing.");
                break;
            case "requires_payment_method":
                this.showMessage("Adding payment method was not successful, please try again.");
                break;
            default:
                this.showMessage("Something went wrong.");
                break;
        }
    }

    async handleSubmit(e) {
        let elements = this.elements
        let return_url = this.return_url
        e.preventDefault();
        this.setLoading(true);

        const {error} = await this.stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: return_url
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            this.showMessage(error.message);
        } else {
            // console.log(error.message)
            this.showMessage("An unexpected error occurred.");
        }

        this.setLoading(false);
    }

    // Fetches a payment intent and captures the client secret
    async initialize() {
        this.setLoading(true)

        let items = this.items
        const response = await fetch(this.initialize_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.getMetaValue("csrf-token")
            },
            credentials: "same-origin",
            body: JSON.stringify({items}),
        });
        const {clientSecret} = await response.json();

        const appearance = {
            theme: 'stripe',
            variables: {
                colorPrimary: '#f6ba27',
            },
        };

        this.elements = this.stripe.elements({appearance, clientSecret});

        const elementOptions = {
            mode: "billing",
            layout: "tabs"
        };

        const addressElement = this.elements.create('address', elementOptions);
        const paymentElement = this.elements.create("payment");
        addressElement.mount('#address-element');
        paymentElement.mount("#payment-element");

        this.setLoading(false)
    }

    showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }, 4000);
    }

// Show a spinner on payment submission
    setLoading(isLoading) {
        if (isLoading) {
            // Disable the button and show a spinner
            document.querySelector("#submit").disabled = true;
            document.querySelector("#spinner").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
        } else {
            document.querySelector("#submit").disabled = false;
            document.querySelector("#spinner").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
        }
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }
}