import {Controller} from 'stimulus';
import {Modal} from 'bootstrap';

export default class extends Controller {
    static targets = ['modal', 'modali']

    connect() {

    }

    modalTargetConnected(element) {
        let modal = new Modal(element)
        modal.show()
    }

    modaliTargetConnected(element) {
        let modali = new Modal(element)
    }

}