import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["searchInput", "results", "resultTemplate"]
    static values = {
        gradeId: {type: Number, default: 1},
        videoUrl: {type: String, default: ""}
    }

    connect() {
        this.hideable = true
    }

    resultTemplateTargetConnected(element) {
        this.resultTemplateJ = $(element).removeAttr('data-search-target').clone()
        clearDropdown(this.resultsJ)
    }

    resultsTargetConnected(element) {
        this.resultsJ = $(element)
        this.resultsJ.hide()
    }

    searchInputTargetConnected(element) {
        this.searchInputJ = $(element)
    }

    showDropdown(event) {
        this.resultsJ.show()
    }

    hideDropdown(event) {
        if (this.hideable) {
            this.resultsJ.hide()
        }
    }

    preventHide(event) {
        this.hideable = false
    }

    allowHide(event) {
        this.hideable = true
    }

    inputCheck(event) {

        clearTimeout(this.timerSearch)

        if (this.searchInputJ.val().length > 0) {
            this.timerSearch = setTimeout(() => {
                $.ajax({
                    datatype: "json",
                    url: this.videoUrlValue + "/search.json",
                    type: "GET",
                    data: jQuery.param({
                        q: this.searchInputJ.val(),
                        g: this.gradeIdValue
                    }),
                    success: (response) => {
                        this.resultsJ.empty()
                        if (response.length === 0) {
                            clearDropdown(this.resultsJ, "No se encontraron resultados.")
                        } else {
                            $.each(response, (i, item) => {
                                this.tmp = this.resultTemplateJ.clone()
                                this.tmp.attr("href", this.videoUrlValue + "/" + item.id)
                                this.tmp.find('.result-image').attr("src", item.cover_page)
                                this.tmp.find('.result-title').text(item.title)
                                this.tmp.find('.result-description').text(item.description)
                                this.tmp.find('.result-subject').text(item.subject_title)
                                this.tmp.find('.result-length').text(item.length)
                                this.resultsJ.append(this.tmp);
                            });
                        }
                    },
                    error: () => {
                        clearDropdown(this.resultsJ, "Connection error")
                    }
                });
            }, 500)
        } else {
            clearDropdown(this.resultsJ)
        }
    }

}

function clearDropdown(element, txt = "Introduce el nombre del video o materia...") {
    element.empty()
    element.append($("<div class='p-2 w-100 text-muted'/>").text(txt))
}