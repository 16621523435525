import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["zip", "zip_id", "city", "state", "country", "neighbourhood", "zip_error"]

    connect() {
    }

    get_location(event) {
        if (this.zip_timer != null) {
            clearTimeout(this.zip_timer);
        }
        this.zip_timer = setTimeout(() => {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').substring(0, 5);
            if (event.target.value.length == 5) {
                fetch('/get_location/' + event.target.value)
                    .then(response => response.json())
                    .then((data) => {
                        if (data.zip_id != null) {
                            this.zip_idTarget.value = data.zip_id;
                            this.cityTarget.value = data.city;
                            this.stateTarget.value = data.state;
                            this.countryTarget.value = data.country;
                            this.neighbourhoodTarget.innerHTML = "<option value=''>Selecciona tu colonia</option>";
                            data.neighbourhoods.forEach((neighbourhood) => {
                                this.neighbourhoodTarget.innerHTML += "<option value='" + neighbourhood.id + "'>" + neighbourhood.title + "</option>";
                            })
                            this.zip_errorTarget.innerHTML = "";
                        } else {
                            this.zip_idTarget.value = "";
                            this.clean_location()
                            this.zip_errorTarget.innerHTML = "Código postal no encontrado";
                        }
                    })
            } else {
                this.clean_location();
                this.zip_errorTarget.innerHTML = "Escribe tu código postal de 5 dígitos";
            }
        }, 200);
    }

    clean_location() {
        this.cityTarget.value = "";
        this.stateTarget.value = "";
        this.countryTarget.value = "";
        this.neighbourhoodTarget.innerHTML = "<option value=''>Escribe tu código postal</option>";
        this.zip_errorTarget.innerHTML = "";
    }
}