import {Controller} from 'stimulus';
import {Modal} from 'bootstrap';

export default class extends Controller {
    static targets = ['challenge']

    connect() {

    }

    challengeTargetConnected(element) {
        let modal = new Modal(element)
        modal.show()
        // console.log("Challenge displayed")
    }

}