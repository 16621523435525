import {Controller} from 'stimulus';

// Global variables declared here are available through all controllers within the page

export default class extends Controller {
    // Local variables using this.{variableName} are available through each individual controller

    static targets = ["level", "grade", "subject", "sequence"]
    static values = {}

    connect() {
    }

    levelTargetConnected(element) {
        this.levelJ = $(element)

    }

    gradeTargetConnected(element) {
        this.gradeJ = $(element)
    }

    subjectTargetConnected(element) {
        this.subjectJ = $(element)
    }

    sequenceTargetConnected(element) {
        this.sequenceJ = $(element)
    }

    populateSequence(event) {
        this.url = "/units/check_sequence.json"
        this.params = jQuery.param({subject_id: this.subjectJ.val()})

        populateSelect(this.url, this.params, this.sequenceJ)
    }

    populateSubject(event) {
        this.url = "/subjects.json"
        this.params = jQuery.param({grade_id: this.gradeJ.val()})

        populateSelect(this.url, this.params, this.subjectJ)
        cleanSelect(this.sequenceJ)

    }

    populateGrade(event) {
        this.url = "/grades.json"
        this.params = jQuery.param({level_id: this.levelJ.val()})

        populateSelect(this.url, this.params, this.gradeJ)
        cleanSelect(this.subjectJ)
        cleanSelect(this.sequenceJ)

    }
}


function populateSelect(url, data, target) {
    $.ajax({
        datatype: "json",
        url: url,
        type: "GET",
        data: data,
        success: (response) => {
            cleanSelect(target)
            target.append($("<option />").val("").text("Selecciona una opción"));
            $.each(response, (i, item) => {
                target.append($("<option />").val(item.id).text(item.title));
            });
        },
        error: () => {
            target.append($("<option />").val("").text("Connection error"));
        }
    });

}

function cleanSelect(target) {
    target.empty()
    target.val("")
}

